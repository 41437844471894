import { Injectable } from "@angular/core";
import { GenericData, LocalData, UserConfig } from "src/app/models/local-data";
import { AuthToken } from "src/app/modules/auth/models/auth.token";
import { AssessmentFilter, LessonDTO, LessonFilter, ReferenceContentDTO, TopicAndContent } from "src/app/modules/class/models/lesson.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class StorageHelper {

  private LOCAL_STORAGE_KEY = `L${environment.appVersion}-${environment.USERDATA_KEY}`;
  private GENERIC_STORAGE_KEY = `G${environment.appVersion}-${environment.USERDATA_KEY}`;

  private fetchLocalStorageData = (): LocalData => {
    if (localStorage) {
      try {
        const lsValue: string | null = localStorage.getItem(this.LOCAL_STORAGE_KEY)
        if (lsValue) {
          //var jsontext = window.btoa(lsValue) as string;
          var form = JSON.parse(lsValue) as LocalData
          return form;
        }
      } catch (error) {
        console.error('LOCAL STORAGE PARSE ERROR', error)
      }
    }
    return new LocalData();
  }

  private updateLocalStorageData = (val: LocalData) => {
    if (!localStorage) {
      return
    }
    try {
      var jsonString = JSON.stringify(val);
      //var lsValue = window.atob(jsonString)
      localStorage.setItem(this.LOCAL_STORAGE_KEY, jsonString)
    } catch (error) {
      console.error('LOCAL STORAGE SAVE ERROR', error)
    }
  }

  private removeLocalStorageData = () => {
    if (!localStorage) {
      return
    }
    try {
      localStorage.removeItem(this.LOCAL_STORAGE_KEY)
    } catch (error) {
      console.error('LOCAL STORAGE REMOVE ERROR', error)
    }
  }

  private fetchGenericStorageData = (): GenericData => {
    if (localStorage) {
      try {
        const lsValue: string | null = localStorage.getItem(this.GENERIC_STORAGE_KEY)
        if (lsValue) {
          //var jsontext = window.btoa(lsValue) as string;
          var form = JSON.parse(lsValue) as GenericData
          return form;
        }
      } catch (error) {
        console.error('LOCAL STORAGE PARSE ERROR', error)
      }
    }
    return new GenericData();
  }

  private updateGenericStorageData = (val: GenericData) => {
    if (!localStorage) {
      return
    }
    try {
      var jsonString = JSON.stringify(val);
      console.log('updateGenericStorageData length : ' + jsonString.length);
      localStorage.setItem(this.GENERIC_STORAGE_KEY, jsonString)
    } catch (error) {
      console.error('LOCAL STORAGE SAVE ERROR', error)
    }
  }

  private removeGenericStorageData = () => {
    if (!localStorage) {
      return
    }
    try {
      localStorage.removeItem(this.GENERIC_STORAGE_KEY)
    } catch (error) {
      console.error('LOCAL STORAGE REMOVE ERROR', error)
    }
  }

  public deleteLocalStorageData(): void {
    this.removeLocalStorageData();
  }

  public deleteGenericStorageData(): void {
    this.removeGenericStorageData();
  }

  public setAuthToken(val: AuthToken): void {
    var old = this.fetchLocalStorageData();
    old.authToken = val;
    var expiry = new Date();
    expiry.setSeconds(expiry.getSeconds() + val.expires_in - 60)
    old.authTokenExpiry = expiry;
    this.updateLocalStorageData(old);
  }
  public getAuthToken(): AuthToken | undefined {
    return this.fetchLocalStorageData().authToken
  }
  public getAuthTokenExpiry(): Date {
    return this.fetchLocalStorageData().authTokenExpiry as Date
  }

  public setMessage(val: string | undefined): void {
    var old = this.fetchGenericStorageData();
    old.message = val;
    this.updateGenericStorageData(old);
  }
  public getMessage(): string | undefined {
    return this.fetchGenericStorageData().message
  }
  public setMyLesson(val?: LessonDTO): void {
    var old = this.fetchGenericStorageData();
    old.lesson = val;
    this.updateGenericStorageData(old);
  }
  public getMyLesson(): LessonDTO {
    return this.fetchGenericStorageData().lesson!;
  }
  public setReferenceContent(val: Array<ReferenceContentDTO>): void {
    var old = this.fetchGenericStorageData();
    old.refContent = val;
    this.updateGenericStorageData(old);
  }
  public getReferenceContent(): Array<ReferenceContentDTO> {
    return this.fetchGenericStorageData().refContent ?? [];
  }
  public setMyLessonFilter(val: LessonFilter): void {
    var old = this.fetchGenericStorageData();
    old.lessonFilter = val;
    this.updateGenericStorageData(old);
  }
  public getMyLessonFilter(): LessonFilter {
    return this.fetchGenericStorageData().lessonFilter ?? new LessonFilter();
  }
  public setUserConfig(val: UserConfig): void {
    var old = this.fetchGenericStorageData();
    old.userConfig = val;
    this.updateGenericStorageData(old);
  }
  public getUserConfig(): UserConfig {
    return this.fetchGenericStorageData().userConfig ?? new UserConfig();
  }
  public setAssessmentFilter(val: AssessmentFilter): void {
    var old = this.fetchGenericStorageData();
    old.assessmentFilter = val;
    this.updateGenericStorageData(old);
  }
  public getAssessmentFilter(): AssessmentFilter {
    return this.fetchGenericStorageData().assessmentFilter ?? new AssessmentFilter();
  }
};

